var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-cell", {
              attrs: {
                title: "产品类型：",
                value: _vm.utils.statusFormat(
                  String(_vm.productType),
                  "ProjectProductType"
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "本次支付节点：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payNode || "-")
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c("van-cell", [
          _c("div", { staticClass: "goods-table" }, [
            _c(
              "table",
              { staticClass: "inner-goods-table", attrs: { border: "1" } },
              [
                _c("tr", [
                  _c("th", { staticClass: "header-th" }, [
                    _vm._v(" 支付要件 ")
                  ]),
                  _c("th", { staticClass: "header-th" }, [_vm._v(" 附件 ")])
                ]),
                _vm._l(_vm.dataList, function(item, index) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(item.payDocsName))]),
                    _c(
                      "td",
                      _vm._l(item.fileDetailInfoVoList, function(item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                download: "",
                                href: _vm._.get(item, "fileUrl")
                              }
                            },
                            [_vm._v(_vm._s(_vm._.get(item, "fileName", "-")))]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                })
              ],
              2
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }