var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-cell", {
              attrs: {
                title: "选择订单：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.orderName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "订单编号：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.orderNo)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方类型：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerType_Name)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerOrgName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方开户行：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.payerAccountBank)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款账号：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.paymentAccount)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方类型：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.collectionMethodType_Name
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.beneficiaryOrgName
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款方开户行：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.beneficiaryAccountBank
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "收款账号：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.accountsReceivable
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款金额：",
                value: _vm.utils.moneyFormat(_vm.appForm.paymentAmount)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "是否对外付汇：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.whetherExchage_Name
                )
              }
            }),
            Number(_vm.appForm.whetherExchage) === 1
              ? _c("van-cell", {
                  attrs: {
                    title: "原币币种：",
                    value: _vm.utils.statusFormat(
                      _vm.appForm.currency,
                      "OriginalCurrency"
                    )
                  }
                })
              : _vm._e(),
            _c("van-cell", {
              attrs: {
                title: "当前汇率：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.currentExchangeRate
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款方式：",
                value: _vm.utils.statusFormat(
                  _vm.appForm.paymentMethod,
                  "PaymentMethod"
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款数量：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.invoicedQuantity)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "是否计息：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.calculateInterest_Name
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "付款备注：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.paymentNotes)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "是否同时开票：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.simultaneousBilling_Name
                )
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }