var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "结算单" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _vm.settlementForm.statementType === 1
                  ? _c("paymentStatementPush", {
                      attrs: {
                        "settlement-detail": _vm.settlementForm,
                        type: "payment"
                      }
                    })
                  : _c("paymentStatement", {
                      attrs: {
                        "detail-tables": _vm.settleList,
                        type: "payment"
                      }
                    })
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "付款信息" } },
            [
              _c("paymentInformation", {
                on: {
                  onPaymentInformationSuccess: _vm.onPaymentInformationSuccess
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "付款结果" } },
            [
              _c("paymentResults", {
                attrs: { "detail-tables": _vm.appDownList }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "支付要件" } },
            [
              _c("paymentRequirements", {
                attrs: {
                  "payment-requirements-bool": _vm.paymentRequirementsBool,
                  "payment-information-data": _vm.paymentInformationData,
                  "product-type": _vm.productType
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "相关附件" } },
            [
              _c("attachment-list", {
                attrs: {
                  "module-name": "PAYMENT",
                  "business-id": _vm.$route.query.paymentId
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "审核意见" } },
            [
              _vm.type !== "all" && _vm.type !== "paymentResults"
                ? _c("workflow-audit", {
                    attrs: {
                      "page-key": "payment_apply",
                      "business-key": _vm.collectionStatementData.paymentId,
                      "task-id": _vm.taskId,
                      variable: {
                        viewUrl: _vm.viewUrl,
                        businessId: _vm.collectionStatementData.paymentId,
                        motif:
                          _vm.collectionStatementData.beneficiaryOrgName +
                          "-" +
                          _vm.collectionStatementData.payerType +
                          "-" +
                          _vm.collectionStatementData.statementMoney
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                : _c("workflow-audit", {
                    attrs: {
                      "page-key": "payment_apply",
                      "business-key": _vm.paymentInformationData.paymentId,
                      "proc-inst-id": _vm.procInstId,
                      "task-id": _vm.taskId,
                      variable: {
                        businessId: _vm.paymentInformationData.paymentId,
                        motif:
                          _vm.paymentInformationData.beneficiaryOrgName +
                          "-" +
                          _vm.paymentInformationData.paymentFundType +
                          "-" +
                          _vm.paymentInformationData.paymentAmount
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }