<template>
  <div class="van-main">
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="产品类型：" :value="utils.statusFormat(String(productType), 'ProjectProductType')" />
        <van-cell title="本次支付节点：" :value="utils.isEffectiveCommon(appForm.payNode || '-')" />
      </van-cell-group>
    </div>
    <div class="van-common-detail">
      <van-cell>
        <div class="goods-table">
          <table class="inner-goods-table" border="1">
            <tr>
              <th class="header-th">
                支付要件
              </th>
              <th class="header-th">
                附件
              </th>
            </tr>
            <tr v-for="(item,index) in dataList" :key="item.id">
              <td>{{ item.payDocsName }}</td>
              <td>
                <div v-for="(item, index) in item.fileDetailInfoVoList" :key="index">
                  <a download :href="_.get(item, 'fileUrl')">{{ _.get(item, 'fileName', '-') }}</a>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    moduleName: {
      type: [String, Number],
      default: ''
    },
    paymentRequirementsBool: {
      type: Boolean,
      default: true
    },
    // 自定义数据类别
    paymentInformationData: {
      type: Object,
      default () {
        return {}
      }
    },
    // 产品类型
    productType: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      appForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: ''
      },
      type: this.$route.query.type,
      detailId: this.$route.query.detailId,
      orderId: this.$route.query.orderId,
      paymentId: this.$route.query.paymentId,
      loading: false,
      finished: false,
      loanData: '',
      dataList: [],
      productTypeName: ''
    }
  },
  created () {
    this.getData()
    this.getProjectProductTypeList()
  },
  methods: {
    getProjectProductTypeList () {
      const params = {
        dctTpCd: 'ProjectProductType'
      }
      this.api.base.dataDict(params).then(result => {
        result.data.value.forEach(item => {
          if (Number(item.dctVal) === this.productType || item.dctVal === this.productType) {
            this.productTypeName = item.dctValNm
          }
        })
      })
    },
    getData () {
      this.api.payment.selMode(this.$route.query.orderId, this.$route.query.paymentId).then(res => {
        this.loanData = res.data.value || {}
        const defaultStatusList = res.data.value.filter(item => {
          return item.defaultStatus === 1
        })
        this.appForm.payNode = defaultStatusList[0].payNode
        this.dataList = [...[], ...defaultStatusList[0].paymentDocsInfoList]
      })
    }
  }
}
</script>

<style lang="less">
</style>
