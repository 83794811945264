<!--
 * @Author: xialijie xy1111@vip.qq.com
 * @Date: 2022-02-17 11:50:23
 * @LastEditors: xialijie xy1111@vip.qq.com
 * @LastEditTime: 2022-07-06 14:56:10
 * @FilePath: \gzb-system-mobile-vue\src\components\payment\payment-information.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="van-main">
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="选择订单：" :value="utils.isEffectiveCommon(appForm.orderName)" />
        <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
        <van-cell title="付款方类型：" :value="utils.isEffectiveCommon(appForm.payerType_Name)" />
        <van-cell title="付款方：" :value="utils.isEffectiveCommon(appForm.payerOrgName)" />
        <van-cell title="付款方开户行：" :value="utils.isEffectiveCommon(appForm.payerAccountBank)" />
        <van-cell title="付款账号：" :value="utils.isEffectiveCommon(appForm.paymentAccount)" />
        <van-cell title="收款方类型：" :value="utils.isEffectiveCommon(appForm.collectionMethodType_Name)" />
        <van-cell title="收款方：" :value="utils.isEffectiveCommon(appForm.beneficiaryOrgName)" />
        <van-cell title="收款方开户行：" :value="utils.isEffectiveCommon(appForm.beneficiaryAccountBank)" />
        <van-cell title="收款账号：" :value="utils.isEffectiveCommon(appForm.accountsReceivable)" />
        <van-cell title="付款金额：" :value="utils.moneyFormat(appForm.paymentAmount)" />
        <van-cell title="是否对外付汇：" :value="utils.isEffectiveCommon(appForm.whetherExchage_Name)" />
        <van-cell v-if="Number(appForm.whetherExchage) === 1" title="原币币种：" :value="utils.statusFormat(appForm.currency, 'OriginalCurrency')" />
        <van-cell title="当前汇率：" :value="utils.isEffectiveCommon(appForm.currentExchangeRate)" />
        <van-cell title="付款方式：" :value="utils.statusFormat(appForm.paymentMethod, 'PaymentMethod')" />
        <van-cell title="付款数量：" :value="utils.isEffectiveCommon(appForm.invoicedQuantity)" />
        <van-cell title="是否计息：" :value="utils.isEffectiveCommon(appForm.calculateInterest_Name)" />
        <van-cell title="付款备注：" :value="utils.isEffectiveCommon(appForm.paymentNotes)" />
        <van-cell title="是否同时开票：" :value="utils.isEffectiveCommon(appForm.simultaneousBilling_Name)" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    detailTables: {
      type: [Array],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      appForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: ''
      },
      loading: false,
      finished: false,
      tableList: {
        list: []
      },
      list: []
    }
  },
  created () {
    this.getInfoById()
  },
  methods: {
    getInfoById () {
      this.api.payment.getInfoById(this.$route.query.detailId).then(res => {
        this.appForm = res.data.value || {}
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less">
</style>
