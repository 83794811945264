<template>
  <div class="van-main has-fixed-btn">
    <van-tabs v-model="active">
      <van-tab title="结算单">
        <div class="van-common-detail">
          <paymentStatementPush v-if="settlementForm.statementType === 1" :settlement-detail="settlementForm" type="payment" />
          <paymentStatement v-else :detail-tables="settleList" type="payment" />
        </div>
      </van-tab>
      <van-tab title="付款信息">
        <paymentInformation @onPaymentInformationSuccess="onPaymentInformationSuccess" />
      </van-tab>
      <van-tab title="付款结果">
        <paymentResults :detail-tables="appDownList" />
      </van-tab>
      <van-tab title="支付要件">
        <paymentRequirements :payment-requirements-bool="paymentRequirementsBool" :payment-information-data="paymentInformationData" :product-type="productType" />
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="PAYMENT" :business-id="$route.query.paymentId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit v-if="type !== 'all' && type !=='paymentResults'" page-key="payment_apply" :business-key="collectionStatementData.paymentId" :task-id="taskId" :variable="{viewUrl:viewUrl,businessId:collectionStatementData.paymentId,motif:`${collectionStatementData.beneficiaryOrgName}-${collectionStatementData.payerType}-${collectionStatementData.statementMoney}`}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        <workflow-audit v-else page-key="payment_apply" :business-key="paymentInformationData.paymentId" :proc-inst-id="procInstId" :task-id="taskId" :variable="{businessId:paymentInformationData.paymentId,motif:`${paymentInformationData.beneficiaryOrgName}-${paymentInformationData.paymentFundType}-${paymentInformationData.paymentAmount}`}" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import paymentStatement from '@/components/payment/payment-statement'
import paymentResults from '@/components/payment/payment-results'
import AttachmentList from '@/components/attachment-list'
import paymentInformation from '@/components/payment/payment-information'
import paymentRequirements from '@/components/payment/payment-requirements'
import WorkflowAudit from '@/components/workflow-audit'
import paymentStatementPush from '@/components/payment/payment-pragmatic-push'
import BackButton from '@/components/back-button'
// import fileList from '@/components/file-list'
export default {
  components: {
    paymentStatement,
    paymentStatementPush,
    paymentResults,
    AttachmentList,
    paymentInformation,
    paymentRequirements,
    WorkflowAudit,
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
    // fileList
  },
  data () {
    return {
      taskId: this.$route.query.taskId,
      id: this.$route.query.id,
      type: this.$route.query.type,
      procInstId: this.$route.query.procInstId,
      active: 0,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: [],
      settlementForm: {
        // 关联合同
        contractNumber: '',
        // 付款方类型
        payerType: '',
        // 付款方企业ID
        payerEnterpriseId: '',
        // 收款方类型
        collectionMethodType: '',
        // 收款方企业ID
        beneficiaryEnterpriseId: '',
        // 付款方开户行
        payerAccountBank: '',
        // 收款方开户行
        beneficiaryAccountBank: '',
        // 付款账号
        paymentAccount: '',
        // 收款账号
        accountsReceivable: '',
        // 费用信息
        statementFeeInfoVoList: [],
        // 货款结算明细
        statementCalculationInfoVoList: [],
        // 结算单货物总价信息
        statementCargoPriceInfoVo: {}
      },
      appDownList: [],
      settleList: {
        name: '1111'
      },
      productType: '',
      collectionStatementData: {},
      viewUrl: '',
      paymentInformationData: {},
      paymentRequirementsBool: false
    }
  },
  computed: {
  },
  created () {
    // 结算单详情
    this.getStatementDetail()
    if (this.$route.query.detailId) {
      this.getAppForm()
    }
  },
  methods: {
    // 获取详情
    getAppForm () {
      this.api.payment.getInfoById(this.$route.query.detailId).then(res => {
        this.productType = res.data.value.productType
        this.collectionStatementData = res.data.value
        this.collectionStatementData.statementMoney = this.collectionStatementData.statementMoney ? this.collectionStatementData.statementMoney : this.collectionStatementData.paymentAmount
        this.viewUrl = '/contract/payment/paymentDeatil?type=all&detailId=' + res.data.value.id + '&paymentId=' + res.data.value.paymentId + '&collectionId=' + res.data.value.paymentId + '&orderId=' + res.data.value.orderId
      })
    },
    getStatementDetail () {
      this.api.collection.statementDetail({ businessid: this.$route.query.collectionId }).then(result => {
        this.settlementForm = this._.assign(this.settlementForm, result.data.value)
      }).finally(() => {
      })
    },
    onPaymentInformationSuccess (res) {
      this.paymentInformationData = res
      this.collectionStatementData = res
      this.productType = res.productType
      if (!this.detailId) {
        this.detailId = this.paymentInformationData.id
      }
      this.viewUrl = '/contract/payment/paymentDeatil?type=all&detailId=' + this.detailId + '&paymentId=' + this.paymentId + '&collectionId=' + this.paymentId + '&orderId=' + this.orderId
      this.paymentRequirementsBool = !this.paymentRequirementsBool
      this.activeName = 'paymentRequirements'
    }
  }
}
</script>

<style lang="less" scoped>

</style>
